<template>
  <div>
    <portal to="page-top-title">Templates</portal>
    <portal to="page-top-right">
      <btn :route="{ name: 'templates-new' }" icon="fa-plus" text>Add New</btn>
    </portal>

    <box-table title="List" url="/templates" :url-query="{ with: ['category:id,name', 'subjectCategories:id,name'] }" data-prop="templates"
               :dataCallback="dataCallback" loader @request-after="requestAfter">
      <template slot="table-header">
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Category</th>
          <th>Type</th>
          <th class="text-center">Active</th>
          <th class="text-center">Has URL</th>
          <th>Stats</th>
          <th class="text-right">Created At</th>
          <th class="text-right"></th>
        </tr>
      </template>
      <template slot="table-body">
        <table-row v-for="template in templates" :key="`templates-tr-${template.id}`" :value="template">
          <td>{{ template.id }}</td>
          <td>{{ template.name }}</td>
          <td>
            <router-link :to="{ name: 'templates-categories-single', params: { id: template.category.id } }">{{ template.category.name }}</router-link>
          </td>
          <td>{{ template.type_text }}</td>
          <td class="text-center">
            <badge-active :value="template.active"/>
          </td>
          <td class="text-center">
            <badge-active :value="template.has_code_url"/>
          </td>
          <td>
            <stats-column :stats="template.stats"/>
          </td>
          <td class="text-right">{{ template.created_at }}</td>
          <td class="text-right">
            <btn :route="{ name: 'templates-edit', params: { id: template.id } }" icon="fa-edit" v-if="template.metadata.editable.this">Edit</btn>
            <btn :route="{ name: 'templates-single', params: { id: template.id } }" color="info" icon="fa-eye">Show</btn>
          </td>
        </table-row>
      </template>
    </box-table>
  </div>
</template>

<script>
import BoxTable from '@/views/components/data/BoxTable'
import BadgeActive from '@/views/components/simple/BadgeActive'
import Btn from '@/views/components/simple/Btn'
import StatsColumn from '@/views/components/stats/StatsColumn'

export default {
  name: 'TemplatesList',
  metaInfo: {
    title: 'Templates'
  },
  components: {
    BoxTable,
    BadgeActive,
    Btn,
    StatsColumn
  },
  data () {
    return {
      templates: []
    }
  },
  methods: {
    dataCallback (data) {
      return data.map((item) => { item.stats = undefined; return item })
    },
    requestAfter () {
      this.loadTemplateStats(
        this.templates.map((list) => list.id)
      )
    },
    loadTemplateStats (ids) {
      this.$http
        .get('/templates/stats', { params: { ids: ids } })
        .then((res) => {
          const { data: { data } } = res

          this.templates.forEach((template) => {
            template.stats = data.find((stats) => stats.id === template.id)
          })
        })
    }
  }
}
</script>
